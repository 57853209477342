.login_panel {
    display: flex;
    min-height: 100vh;
}

a {
    color: #BEAFE2 !important;
    text-decoration: none !important;
    transition: all .3s linear;
}

a:hover {
    color: #FFBB00 !important;
}

.login_panel .login_form {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5%;
    padding-bottom: 5%;
}

.login_panel .side_img {
    width: 60%;
    height: 100vh;
    position: fixed;
    right: 0;
}

.login_panel .side_img img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
}

.login_panel form {
    width: 100%;
    max-width: 375px;
}

.login_panel form span {
    color: #D6B987;
    padding-right: 8px;
}

.login_panel form h5 {
    color: #FFBB00;
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 15px;
}

.login_panel form p {
    font-size: 14px;
    color: #D6B987;
}

.login_panel form label {
    color: #D6B987;
    font-size: 15px;
    margin-bottom: 10px;
}

.login_panel .title_box {
    padding-bottom: 22px;
    margin-bottom: 22px;
    border-bottom: 1px solid #D6B987;
}

.login_panel form textarea,
.login_panel form input {
    background: rgba(171, 141, 96, 0.08);
    border: 1px solid rgba(214, 185, 135, 0.5);
    border-radius: 6px;
    padding: 12px 15px;
    padding-left: 24px;
    color: #FFBB00;
    transition: all .3s linear;
}

.logo_box {
    text-align: left;
    margin-bottom: 20px;
}

.login_panel form textarea:focus,
.login_panel form input:focus {
    box-shadow: none !important;
    background-color: rgba(171, 141, 96, 0.08);
    border-color: #FFBB00;
    color: #D6B987;
    transition: all .3s linear;
}

.login_panel form .form-control::placeholder {
    color: #D6B987;
    font-size: 14px;
    opacity: 1;
    transition: all .3s linear;
}

.login_panel .dplay {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.login_panel .form-check {
    display: flex;
    align-items: center;
}

.login_panel .form-check .form-check-input {
    padding: 0px;
}

.login_panel .form-check label {
    margin-bottom: 0px;
    font-size: 14px;
    padding-left: 8px;
}

.forgot_pass {
    font-size: 14px;
}

.send_op {
    display: block;
    text-align: right !important;
}

.send_in {
    color: #BEAFE2 !important;
    font-size: 14px;
}
/* added by Aditi */
.login_panel form  .contact_btn{
color: #1F2628;

}
/* added by Aditi */