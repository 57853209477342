body {
  font-family: "Inter", sans-serif !important;
  background-color: #1f2628 !important;
}

ul,
ol,
li {
  list-style: none;
  padding-left: 0px !important;
}

a {
  text-decoration: none !important;
}

.product_btn {
  padding: 20px 30px;
  color: #111827;
  background-color: #ffbb00;
  border-radius: 12px;
  border: none;
  font-size: 18px;
  font-weight: 700;
  z-index: 9;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.product_btn:hover {
  color: #fb7112 !important;
  background-color: #fff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}

.formatted__description {
  white-space: pre-line !important;
}

.err_red {
  color: red !important;
  float: left;
}

.cart__fill {
  background: #ffbb00 !important;
  color: #111827 !important;
  display: flex !important;
  align-items: center;
}

.cart__fill span {
  padding-left: 7px;
}

.checkout__control .form-control {
  color: #d6b987 !important;
  font-size: 14px;
}

.edit__text {
  color: #ffbb00;
  cursor: pointer;
}

.stripe__image {
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  text-align: center;
  object-fit: contain;
  padding: 10px 30px;
}

.blog__sort {
  border: none !important;
  float: right;
  min-width: 125px !important;
}
