.header {
  position: fixed;
  width: 100%;
  z-index: 8;
  background-color: #1f2628;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.5);
}

.openheader {
  position: fixed;
  width: 100%;
  z-index: 8;
  background-color: #1f2628;
}

.header .navbar-nav {
  display: flex;
  align-items: center;
}

.openheader .navbar-nav .nav-link,
.header .navbar-nav .nav-link {
  color: #6b7280 !important;
  font-weight: 500;
  transition: all 0.3s linear;
  padding: 8px 18px;
  border-radius: 8px;
  margin-left: 8px;
  margin-right: 8px;
}

.shop_dr {
  background-color: transparent !important;
  border: none !important;
  color: #6b7280 !important;
}

.dropdown .dropdown-menu {
  background: #2b2e2d !important;
  border: 1px solid rgba(214, 185, 135, 0.5) !important;
  border-radius: 6px !important;
}

.shop_dr::after {
  border: solid #6b7280 !important;
  border-width: 0 2px 2px 0 !important;
  display: inline-block !important;
  padding: 2px !important;
  border-radius: 1px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.shop_dr:focus {
  box-shadow: none !important;
}

.shop_dr:hover,
.header .navbar-nav .nav-link:hover {
  background: #ffbb00 !important;
  color: #111827 !important;
}

.header .navbar-nav .schedule {
  background-color: #ffbb00;
  color: #111827 !important;
  padding: 15px 28px;
  font-weight: 700;
}

.header .navbar-nav .schedule:hover {
  color: #fb7112 !important;
  background-color: #fff;
}

.header .navbar-nav .icon_shopping:hover {
  background-color: transparent;
}

.header .dropdown .dropdown-menu a {
  color: #fff !important;
  margin-bottom: 8px;
  padding-left: 12px;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 0px;
}

.icon_shopping:hover {
  background-color: transparent !important;
}

.offer_build {
  border-top: 1px solid #fb7112;
  border-bottom: 1px solid #fb7112;
  background-color: #1f2628;
  position: fixed;
  width: 100%;
  z-index: 7;
  top: 74px;
}

.tarot_web {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 2px;
}

.offer_build .box_3 {
  border-left: 1px solid #fb7112;
  border-right: 1px solid #fb7112;
}

.offer_build .tarot_web .exclusive_box {
  color: #fff;
  text-align: center;
}

.offer_build .tarot_web .exclusive_box span {
  font-size: 12px;
  font-weight: 300;
  display: block;
  margin-bottom: 5px;
}

.offer_build .tarot_web .exclusive_box h5 {
  font-size: 13px;
  margin-bottom: 0px;
}
/*--hero section--*/

.hero_top {
  background-color: #1f2628;
  padding-top: 15%;
  padding-bottom: 60px;
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.hero_top::before {
  background-image: url(../images/bg_hero.svg);
  background-repeat: no-repeat;
  position: absolute;
  content: "";
  top: 62%;
  transform: translateY(-50%);
  width: 100%;
  left: 41px;
  max-width: 555px;
  height: 637px;
  z-index: 0;
}

.hero_top .hero_main {
  position: absolute;
  z-index: 3;
  top: 50%;
  width: 100%;
  padding-top: 10%;
  transform: translateY(-50%);
}

.hero_top .hero_main .open_hero h5 {
  font-size: 60px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 30px;
}

.open_hero {
  display: flex;
  height: 100%;
  align-items: center;
}

.hero_top .hero_main .open_hero p {
  font-size: 20px;
  color: #6b7280;
  font-weight: 400;
  margin-bottom: 30px;
}

.hero_top .hero_top .hero_img {
  width: 100%;
}

.hero_top .hero_img img {
  width: 100%;
  height: 450px;
  object-fit: contain;
  object-position: center;
}
/*--Popular Reading--*/

.popular_read {
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #1f2628;
}

.popular_read .pop_read {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.popular_read .pop_read h5 {
  color: #ffbb00;
}

.popular_read .pop_read span {
  color: #beafe2;
  font-size: 14px;
}
/*--Blog--*/

.blog_panel {
  padding-top: 60px;
  padding-bottom: 90px;
  background-color: #1f2628;
}

.blog_row {
  text-align: center;
  margin-bottom: 50px;
}

.blog_row h5 {
  font-size: 40px;
  font-weight: 800;
  color: #ffbb00;
  margin-bottom: 25px;
}

.blog_row p {
  color: #fff;
  font-size: 20px;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 20px;
}
/*--Banner Box--*/

.banner_bg {
  padding-top: 120px;
  padding-bottom: 120px;
  background-repeat: no-repeat;
  background-image: url(../images/bg_box.png);
  background-size: cover;
}

.banner_bh {
  text-align: center;
}

.banner_bh h5 {
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 25px;
}

.banner_bh p {
  width: 100%;
  max-width: 468px;
  margin: 0 auto;
  margin-bottom: 30px;
}
/*--Reviews--*/

.reviews {
  padding-top: 60px;
  padding-bottom: 100px;
  background-color: #1f2628;
}

.review_box img {
  margin-bottom: 30px;
}

.review_box p {
  color: #6b7280;
  font-weight: 700;
  margin-bottom: 22px;
  line-height: 27px;
}

.review_box span {
  color: #beafe2;
  font-weight: 700;
}
/*--Footer__*/

.footer {
  background-color: #1f2628;
  padding-bottom: 50px;
  color: #d1d5db;
}

.footer .logo_box {
  padding-top: 50px;
  text-align: center;
  border-top: 1px solid #fb7112;
  margin-bottom: 30px;
}

.footer .logo_box img {
  width: 80px;
}

.footer .foot_bottom {
  display: flex;
  justify-content: space-between;
}

.quik_links {
  width: 181px;
  margin: 0 auto;
  margin-bottom: 60px;
}

.quik_links .social_link {
  display: flex;
  align-items: center;
}

.quik_links .social_link a {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #fff !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fb7112;
  margin-right: 15px;
}

.quik_links h5 {
  margin-bottom: 20px;
  color: #ab8d60;
  font-size: 20px;
}

.quik_links ul li {
  margin-bottom: 20px;
  font-weight: 300;
}

.quik_links ul li a {
  color: #fff !important;
}

.anydesk_open {
  display: flex;
  align-items: center;
  padding-left: 80px;
  padding-right: 80px;
  justify-content: space-between;
}

.access_link span {
  margin-left: 40px;
}

.access_link span img {
  margin-right: 12px;
}
/*--Product Page--*/

.blogbar {
  padding-top: 6% !important;
}

.product_bar {
  /* added by Aditi */
  background: linear-gradient(
    180deg,
    #1f2628 0%,
    rgb(214 185 135 / 27%) 165.55%
  );
  /* added by Aditi */
  padding-top: 10%;
  padding-bottom: 100px;
}

.product_bar .pop_read {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #fb7112;
  padding-bottom: 12px;
  margin-bottom: 20px;
}

.product_bar .pop_read h5 {
  color: #ffbb00;
  font-size: 26px;
  font-weight: 700;
}

.product_bar .pop_read span {
  color: #fff;
}

.product_bar .pop_read select {
  cursor: pointer;
  width: 100%;
  /* padding: 8px 10px; */
  max-width: 120px;
  /* reading page sort icon by Aditi  */
  height: 39px;
  /* padding: 8px 10px; */
  /* reading page sort by Aditi  */

  /* max-width: 80px; */
  background-color: #ab8d60;
  color: #fff !important;
  border: none;
  font-size: 14px;
  background-image: url(../images/sam.svg);
}

.product_bar .pop_read select:focus {
  border: none !important;
  box-shadow: none !important;
}

.name_form label {
  color: #fff;
}
/*--Disclaimer--*/

.name_form textarea {
  background: rgba(171, 141, 96, 0.08) !important;
  border: 1px solid rgba(214, 185, 135, 0.5) !important;
  border-radius: 6px !important;
  font-size: 14px;
  color: #fff !important;
}

.open_disclaimer {
  padding-top: 19%;
  padding-bottom: 8%;
}

.name_form textarea:focus,
.form-check-input:focus {
  box-shadow: none !important;
}

.form-check-input:checked {
  background-color: #ffbb00 !important;
}

.disclaimer {
  background: rgba(171, 141, 96, 0.08);
  border: 1px solid rgba(214, 185, 135, 0.5);
  border-radius: 19px;
  padding: 80px 40px;
  text-align: center;
  color: #fff;
}

.disclaimer h5 {
  color: #ffbb00;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 20px;
}

.disclaimer p {
  margin-bottom: 8px;
  font-weight: 300;
  font-size: 15px;
}
/*--Faq--*/

.faq {
  text-align: unset;
}

.faq .faq_box {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.faq .accordion .accordion-item {
  background-color: transparent;
  margin-bottom: 5px;
  border: none;
  border-bottom: 1px solid #fb7112;
  padding: 10px 2px;
}

.faq .accordion .accordion-item h2 .accordion-button {
  color: #d6b987;
  /* Added by Aditi alaign FAQ ques  */
  padding: 1rem 0 !important;
  /* Added by Aditi alaign FAQ ques  */
}

.faq .accordion .accordion-item .accordion-button {
  background-color: transparent;
}

.faq .accordion-body {
  border-top: none;
}

.faq .accordion-button::after {
  background-image: url(../images/Chevron-down.svg);
}

.faq .accordion-button:not(.collapsed)::after {
  background-image: url(../images/Chevron.svg);
}

.faq .accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  box-shadow: none !important;
}

.faq h5 {
  border-bottom: 2px solid #fb7112;
  padding-bottom: 20px;
  text-align: center;
  margin-bottom: 0px;
}

.faq .accordion-body {
  font-size: 15px;
  color: #fff;
  font-weight: 300;
  padding-top: 0px;
  padding-left: 0px;
}

.nope_acc {
  border-bottom: none !important;
}
/*--Terms--*/

.terms {
  text-align: unset;
}

.terms h5 {
  text-align: center;
}

.terms .terms_box {
  margin-bottom: 25px;
}

.terms .terms_box h4 {
  font-size: 20px;
  color: #d6b987;
  font-weight: 500;
  margin-bottom: 25px;
}

.terms .terms_box p {
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: 300;
}

.terms .new_op p {
  margin-bottom: 28px;
}
/*--shopping cart--*/

.shop_cart_box {
  padding-top: 8%;
  padding-bottom: 80px;
}

.shop_head {
  color: #ffbb00;
  font-size: 30px;
  font-weight: 700;
  padding-bottom: 30px;
  border-bottom: 1px solid #e5e7eb;
  margin-bottom: 20px;
}

.shop_cart_box .cart_box {
  display: flex;
  position: relative;
  margin-bottom: 40px;
  border-bottom: 1px solid #e5e7eb;
  padding-bottom: 40px;
}

.shop_cart_box .cart_box .product_img {
  width: 100%;
  max-width: 300px;
  height: 300px;
  margin-right: 25px;
}

.shop_cart_box .cart_box .product_img img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.shop_cart_box .cart_box .product_name {
  position: relative;
}

.shop_cart_box .cart_box .product_name h5 {
  color: #ab8d60;
  margin-bottom: 14px;
}

.shop_cart_box .cart_box .product_name .price {
  color: #fff;
  font-size: 20px;
  margin-bottom: 14px;
}

.shop_cart_box .time_date {
  display: flex;
  align-items: center;
}

.shop_cart_box .time_date svg {
  font-size: 30px;
}

.shop_cart_box .time_date .timebox {
  color: #6b7280;
  margin-left: 12px;
}

.shop_cart_box .time_date .timebox span,
.shop_cart_box .time_date .timebox span {
  display: block;
  font-weight: 600;
}

.shop_cart_box .stock_status {
  color: #ab8d60;
  font-size: 16px;
  position: absolute;
  bottom: 0px;
}

.shop_cart_box .stock_status svg {
  font-size: 30px;
}

.shop_cart_box .stock_status span {
  padding-left: 6px;
}

.shop_cart_box .close {
  position: absolute;
  font-size: 30px;
  right: 0;
  top: 0;
  cursor: pointer;
}

.shop_cart_box .summery_box {
  background: rgba(171, 141, 96, 0.08);
  border: 1px solid rgba(214, 185, 135, 0.5);
  border-radius: 6px;
  padding: 25px 40px;
}

.shop_cart_box .summery_box h5 {
  font-size: 24px;
  font-weight: 600;
  color: #ffbb00;
  margin-bottom: 30px;
}

.shop_cart_box .summery_box p {
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #fb7112;
  margin-bottom: 20px;
}

.shop_cart_box .summery_box .total_order {
  border-bottom: none;
}

.shop_cart_box .summery_box .total_order span:nth-child(1) {
  color: #6b7280;
  color: #beafe2;
  font-size: 18px;
}

.shop_cart_box .view_product {
  color: #ffbb00;
  margin-bottom: 20px;
}

.shop_cart_box .img_pro {
  width: 100%;
  height: 300px;
  margin-bottom: 20px;
}

.shop_cart_box .img_pro img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.shop_cart_box .pro_details {
  justify-content: space-between;
  color: #fff;
}

.shop_cart_box .pro_details h5 {
  color: #ab8d60;
}

.blog_details_box {
  padding: 8%;
  padding-bottom: 80px;
}

.blog_details_box .header_blog {
  font-size: 45px;
  color: #fff;
  width: 100%;
  max-width: 460px;
  font-weight: 700;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 30px;
}

.blog_details_box .blog_op {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.blog_details_box .blog_op .user_img {
  width: 100%;
  max-width: 38px;
  height: 38px;
  border-radius: 50%;
  margin-right: 12px;
}

.blog_details_box .blog_op .user_img img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.blog_details_box .blog_op .user_name name {
  color: #ab8d60;
  display: block;
}

.blog_details_box .blog_op .user_name status {
  color: #fff;
}

.blog_details_box .content_img {
  width: 100%;
  height: 600px;
  margin-bottom: 30px;
}

.blog_details_box .content_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.blog_details_box .blog_content {
  color: #fff;
  margin-bottom: 30px;
}

.blog_details_box .blog_content p {
  margin-bottom: 30px;
}

.blog_details_box .blog_content h5 {
  font-size: 20px;
  font-weight: 700;
}

.blog_details_box a {
  font-size: 18px;
  color: #ab8d60 !important;
}

.blog_details_box a span {
  padding-left: 8px;
}

.about {
  padding-top: 12%;
}

.about_box {
  background: rgba(171, 141, 96, 0.08);
  border: 1px solid rgba(214, 185, 135, 0.5);
  border-radius: 31px;
  padding: 20px;
  color: #fff;
  position: relative;
  text-align: center;
  margin-bottom: 20px;
  padding-top: 12%;
}

.about_box h5 {
  color: #ab8d60;
  font-weight: 600;
  margin-bottom: 22px;
}

.about_box p {
  font-size: 14px;
  font-weight: 300;
}

.about_box span {
  width: 50px;
  height: 50px;
  background-color: #ffbb00;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -2px;
  left: 44%;
  transform: translateY(-50%);
  border-radius: 50%;
}

.user_title {
  text-align: center;
  color: #ffbb00;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 30px;
}

.user_review .blog_op {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.user_review .blog_op .user_img {
  width: 100%;
  max-width: 38px;
  height: 38px;
  border-radius: 50%;
  margin-right: 12px;
}

.user_review .blog_op .user_img img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.user_review .blog_op .user_name name {
  color: #beafe2;
  display: block;
  font-size: 20px;
  font-weight: 700;
}

.user_review .blog_op .user_name rating {
  color: #ffbb00;
}

.user_review {
  padding: 40px 30px;
  background: rgba(171, 141, 96, 0.08);
  border: 1px solid rgba(214, 185, 135, 0.5);
  border-radius: 31px;
  margin-bottom: 50px;
}

.user_review .firdt_box {
  padding-bottom: 25px;
  border-bottom: 1px solid #beafe2;
  margin-bottom: 24px;
}

.user_review p {
  color: #fff;
  margin-bottom: 24px;
}

.user_review .img_review {
  display: flex;
  align-items: center;
}

.user_review .img_review img {
  width: 100%;
  max-width: 200px;
  height: 200px;
  margin-right: 20px;
}

.meet_box img {
  width: 100%;
  height: 500px;
  object-fit: cover;
  object-position: center;
  margin-bottom: 30px;
}

.meet_box p {
  color: #fff;
  font-size: 18px;
  font-weight: 300;
}

.meet_bg .left_box h5 {
  font-size: 40px;
  font-weight: 800;
  color: #ffbb00;
  margin-bottom: 16px;
}

.meet_bg .left_box span {
  margin-bottom: 12px;
  display: block;
}

.meet_bg .left_box p {
  color: #fff;
}

.meet_bg .product_photo {
  width: 100%;
  height: 420px;
}

.meet_bg .product_photo img {
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 100%;
}

.merchant_class .merchant img {
  object-fit: contain !important;
}

.checkout {
  padding-top: 10%;
  background: linear-gradient(
    90deg,
    rgba(31, 38, 40, 1) 50%,
    rgba(30, 30, 30, 1) 50%
  );
  margin-bottom: 121px;
}

.checkout label {
  color: #d6b987;
  font-size: 14px;
}

.checkout .form-control {
  background: rgba(171, 141, 96, 0.08) !important;
  border: 1px solid rgba(214, 185, 135, 0.5) !important;
  border-radius: 6px !important;
  padding: 10px 15px;
  font-size: 14px;
}

.checkout input:focus {
  box-shadow: none !important;
}

.disclaimer .form-check-input {
  padding: 0px !important;
}

.disclaimer form textarea,
.disclaimer form input {
  background: rgba(171, 141, 96, 0.08);
  border: 1px solid rgba(214, 185, 135, 0.5);
  border-radius: 6px;
  padding: 12px 15px;
  padding-left: 24px;
  color: #ffbb00;
  transition: all 0.3s linear;
}

.disclaimer .form-label {
  text-align: left !important;
  display: flex;
  align-items: center;
  color: #ab8d60;
  font-size: 14px;
}

.disclaimer select:focus,
.disclaimer form textarea:focus,
.disclaimer form input:focus {
  box-shadow: none !important;
  background-color: rgba(171, 141, 96, 0.08);
  border-color: #ffbb00;
  color: #d6b987;
  transition: all 0.3s linear;
}

.disclaimer select {
  background-color: #ffbb00 !important;
  color: #1f2628 !important;
  padding: 12px 15px;
  width: 100%;
  max-width: 400px;
}

.disclaimer .form-check {
  display: block;
  margin-bottom: 20px;
}

.disclaimer .form-check label {
  display: block;
  text-align: left !important;
  width: unset !important;
  height: unset !important;
}

.disclaimer button {
  padding: 10px 30px !important;
  font-size: 16px !important;
}

.date_check {
  background-color: transparent !important;
  border: none !important;
}

.dilbychance .disclaimer {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 20px !important;
  text-align: left !important;
}

.shopping_bag_checkout {
  color: #fff;
}

.pup_up {
  width: 100%;
  max-width: 230px;
  height: 200px;
  margin-right: 20px;
}

.pup_up img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.flex_check {
  display: flex;
  margin-bottom: 200px;
}

.flex_check .pupup_details {
  position: relative;
}

.flex_check .pupup_details h5 {
  color: #ffbb00;
}

.flex_check .pupup_details p {
  color: #d6b987;
}

.flex_check .time_date {
  display: flex;
  align-items: center;
}

.flex_check .time_box svg {
  font-size: 30px;
}

.flex_check .time_box .timebox {
  color: #6b7280 !important;
  margin-left: 12px;
}

.flex_check .pupup_details .op_cart {
  position: absolute;
  bottom: 0;
  color: #ffbb00;
  cursor: pointer;
}

.sub_total_bag p {
  display: flex;
  justify-content: space-between;
  padding: 30px 0px;
}

.sub_total_bag .sub {
  border-bottom: 1px solid #1f2628;
}

.sub_total_bag .one_end span:nth-child(1) {
  color: #ffbb00;
  font-weight: 600;
}
.custom_margin {
  margin-bottom: 100px !important;
}

.margin__auto {
  display: flex;
  justify-content: center;
}


.text_overflow {
  max-width: 400px;
  height: 76.2px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
}
.blog_Tflow{
  width: 380px;
  height: 30px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.myblog_p{
  color: #fff;
  line-height: 24px;
  font-weight: 300;
  max-width: 400px;
  height: 46px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}