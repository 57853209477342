.op_main {
    padding-top: 39%;
}

.product_card {
    background: rgba(171, 141, 96, 0.08);
    border: 1px solid rgba(214, 185, 135, 0.5);
    border-radius: 31px;
    text-align: center;
    padding: 30px;
    position: relative;
    padding-top: 36%;
}
.popular_read .product_card{
    
    min-height: 385px;

}
.popular_read .product_card h5{
    
    max-width: 400px;
    height: 76.2px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

}

.product_card h5 {
    font-size: 30px;
    font-weight: 500;
    color: #AB8D60;
    margin-bottom: 30px;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.product_card h4 {
    font-size: 30px;
    color: #F4E0C2;
    font-family: 'Cinzel', serif;
    font-weight: 700;
    letter-spacing: 7px;
    margin-bottom: 20px;
}

.product_card img {
    position: absolute;
    top: -104px;
    left: 50%;
    transform: translateX(-50%);
}


/*--Blog Box--*/

.blog_open {
    background: linear-gradient(180deg, rgba(171, 141, 96, 0.2) 73%, rgba(171, 141, 96, 0.5) 100%);
    border: 1px solid rgba(214, 185, 135, 0.5);
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 30px;
}

.blog_open .blog_content img {
    height: 200px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-bottom: 1px solid rgba(214, 185, 135, 0.5);
}

.blog_content .blog_c {
    padding: 30px 20px;
}

.blog_content .blog_c span {
    color: #BEAFE2;
    margin-bottom: 7px;
    display: block;
}

.blog_content .blog_c h5 {
    color: #fff;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 7px;
}

.blog_content .blog_c p {
    color: #fff;
    line-height: 24px;
    font-weight: 300;
}

.blog_writer {
    padding: 15px 20px;
    display: flex;
}

.blog_writer .blog_wri {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 18px;
}

.blog_writer .writer_blog span {
    color: #CEAA73;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 3px;
    display: block;
}

.blog_writer .writer_blog h5 {
    color: #fff;
    font-weight: 400;
    font-size: 18px;
}

.merchant {
    width: 100%;
    max-width: 130px;
}

.merchant_class {
    padding-top: 4%;
}

.merchant_class .product_card {
    padding-top: 50px;
    margin-bottom: 30px;
}

.merchant_class .product_card img {
    position: unset;
    transform: unset;
    margin-bottom: 20px;
    object-fit: contain;
    max-width: 200px;
    height: 200px;
}