.order_page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 100vh;
    padding-top: 30px;
    padding-bottom: 30px;
}

.order_page .order_details {
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
    padding: 20px;
    width: 100%;
    max-width: 600px;
}

.order_page .order_details .para {
    color: #fff;
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(63, 63, 63);
    margin-bottom: 30px;
}

.order_page .order_details .sub_total_bag h5 {
    color: #fff;
}

.order_page .order_details .sub_total_bag p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    padding: 10px 0px;
    margin-bottom: 0px;
}

.order_page .open_sun {
    border-bottom: 1px solid rgb(63, 63, 63);
}