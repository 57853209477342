.loader-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  /* background-color: #1e1e1e; */
  background-color: rgb(245, 245, 245);
  display: grid;
  place-items: center;
  opacity: 0.5;
}

.loader {
  border: 8px solid #e3e3e3;
  border-top: 8px solid #ffbb00;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
