.modal {
    background: rgba(31, 38, 40, 0.5);
}

.modal-header {
    text-align: right;
    color: #fff;
    font-size: 30px;
}

.modal-dialog {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-dialog .modal-content {
    background: rgba(171, 141, 96, 0.08) !important;
    border: 1px solid rgba(214, 185, 135, 0.5) !important;
    border-radius: 15px;
}

.modal-dialog .modal-content .modal-body {
    padding-top: 0px;
}

.modal-dialog .modal-content .modal-header,
.modal-dialog .modal-content .modal-body {
    border: none;
}

.modal-dialog .modal-content .modal-header span {
    background: rgba(255, 255, 255, 0.15);
    border: 1px solid #80745A;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 40px;
    color: #fff;
}

.modal-dialog .modal-content p {
    font-size: 20px;
    color: #D6B987;
    width: 100%;
    max-width: 367px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 20px;
}

.faq_form label {
    color: #D6B987;
    font-size: 15px;
    margin-bottom: 10px;
}

.faq_form input {
    background: rgba(171, 141, 96, 0.08);
    border: 1px solid rgba(214, 185, 135, 0.5);
    border-radius: 6px;
    padding: 12px 15px;
    padding-left: 24px;
    color: #FFBB00;
    transition: all .3s linear;
}

.faq_form input:focus {
    box-shadow: none !important;
    background-color: rgba(171, 141, 96, 0.08);
    border-color: #FFBB00;
    color: #D6B987;
    transition: all .3s linear;
}